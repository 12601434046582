import {
  DocumentPlusIcon,
  DocumentCheckIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import Notification from "./notification";
import Success from "./success";
import Deploy from "./deploy";

export default function DeployApp() {
  const [deploymentSuccessful, setDeploymentSuccessful] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [domain, setDomain] = useState("");
  const [domainAvailable, setDomainAvailable] = useState(true);
  const [isDomainFieldFocused, setIsDomainFieldFocused] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showDeployComponent, setShowDeployComponent] = useState(false);

  const handleDeploySite = async () => {
    try {
      setProcessing(true);
      const formData = new FormData();
      formData.append("subdomain", domain);
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      const endpoint = `${process.env.REACT_APP_API_URL}/api/website`;
      const options = {
        method: "POST",
        credentials: 'include',
        body: formData,
      };

      const response = await fetch(endpoint, options);
      await response.json();
      if (response.ok) {
        setDeploymentSuccessful(true);
        showNotification("Deployment was successful. Please wait", "success");
      } else {
        showNotification("Deployment was not successful", "error");
      }
    } catch (error) {
      showNotification("Deployment was not successful", error, "error");
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDomainFieldFocused && domain) {
        checkDomainAvailability();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [domain, isDomainFieldFocused]);

  const checkDomainAvailability = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/api/website/domains/available?domain=${domain}`;
      const options = {
        method: "GET",
        credentials: 'include',
      };
      const response = await fetch(endpoint, options);
      if (response.ok) {
        setDomainAvailable(true);
        setFetchError(false);
        setIsDomainFieldFocused(false);
        showNotification("Domain is available", "success");
      } else {
        setDomainAvailable(false);
        setFetchError(true);
        showNotification("Domain invalid or already used", "error");
      }
    } catch (error) {
      setDomainAvailable(false);
      setFetchError(true);
      showNotification("Error checking domain availability", "error");
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleRemoveSelectedFiles = (e) => {
    e.stopPropagation();
    setSelectedFiles([]);
  };
  

  const handleCancel = () => {
    setShowDeployComponent(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
  };

  return (
    <>
      {deploymentSuccessful ? (
        <Success domain={domain} />
      ) : showDeployComponent ? (
        <Deploy /> // Show the Deploy component when the state is true
      ) : (
        <form>
          {notification && (
            <Notification
              message={notification.message}
              type={notification.type}
            />
          )}
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Deploy Website
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Get started by uploading your website files.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full flex justify-center">
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="domain"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Website URL
                    </label>
                    <div
                      className={`mt-2 flex rounded-md shadow-sm ring-1 ring-inset ${
                        fetchError
                          ? "ring-red-600"
                          : domainAvailable
                          ? "ring-indigo-600"
                          : "ring-gray-300"
                      } focus-within:ring-2 focus-within:ring-inset sm:max-w-md`}
                    >
                      <input
                        type="text"
                        name="domain"
                        id="domain"
                        autoComplete="domain"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="mywebsite"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        onFocus={() => setIsDomainFieldFocused(true)}
                        onBlur={() => setIsDomainFieldFocused(false)}
                      />
                      <span className="flex select-none items-center pr-2 pl-2 text-gray-500 sm:text-sm">
                        .apps.webpagent.ch
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className="col-span-full relative"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Upload your website data
                  </label>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Upload your website files with .html .css and .js extentions
                    directly or with sub-folders as .zip file. (max. 10MB)
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    When you have images or other media files larger 10MB, try
                    using our cdn service and refer to the files with the
                    provided cdn-url.
                  </p>
                  <label
                    htmlFor="file-upload"
                    className="mt-2 flex flex-wrap justify-center items-center rounded-lg border cursor-pointer border-dashed border-gray-900/25 px-6 py-10 relative"
                  >
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileSelect}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <button
                        type="button"
                        className="absolute top-0 right-0 p-1 m-2 bg-white rounded-full"
                        onClick={handleRemoveSelectedFiles}
                      >
                        <TrashIcon className="h-5 w-5 text-red-500" />
                      </button>
                    )}
                    {selectedFiles.length === 0 ? (
                      <>
                        <DocumentPlusIcon
                          className="h-8 w-8 text-gray-300"
                          aria-hidden="true"
                        />
                        <span className="mt-1 ml-1 text-xs text-gray-600">
                          Drag and drop your files or click in this box to select files to upload (max. 10MB)
                        </span>
                      </>
                    ) : (
                      selectedFiles.map((file) => (
                        <div
                          key={file.name}
                          className="flex flex-col items-center mr-6 mb-4"
                        >
                          <DocumentCheckIcon
                            className="h-8 w-8 text-gray-300"
                            aria-hidden="true"
                          />
                          <p className="mt-1 text-xs text-gray-600">
                            {file.name}
                          </p>
                        </div>
                      ))
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={handleCancel} // Attach handleCancel function to cancel button
            >
              Cancel
            </button>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleDeploySite}
              disabled={
                domainAvailable === false ||
                selectedFiles.length === 0 ||
                processing
              }
            >
              {processing ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4zm16-8h-4a7.96 7.96 0 01-2 4.709V17c4.418 0 8-3.582 8-8h-4zm-2-5.291V0c-4.418 0-8 3.582-8 8h4c0-2.481 1.346-4.623 3.341-5.291z"
                    />
                  </svg>
                  <span>Processing...</span>
                </div>
              ) : (
                <span>Deploy Website</span>
              )}
            </button>
          </div>
        </form>
      )}
    </>
  );
}
