import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import md5 from "md5";
import {
  Bars3Icon,
  CpuChipIcon,
  GlobeAltIcon,
  HomeIcon,
  XMarkIcon,
  ServerIcon,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline";

import Deploy from "./home/deploy.js";
import Websites from "./websites/websites.js";
import Containers from "./containers/containers.js";
import CDN from "./CDN/cdn.js";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: false },
  { name: "Websites", href: "#", icon: ServerIcon, current: false },
  { name: "CDN", href: "#", icon: GlobeAltIcon, current: false },
  { name: "Containers", href: "#", icon: CpuChipIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [activeNavItem, setActiveNavItem] = useState("Home");
  const [gravatar, setGravatar] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_API_URL}/api/userinfo`;
        const options = {
          method: "GET",
          credentials: 'include',
        };
        const response = await fetch(endpoint, options);
        if (response.ok) {
          const data = await response.json();
          setUsername(data.username);
          setEmail(data.email);
        } else {
          throw new Error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const hashedEmail = md5(email.trim().toLowerCase());
    setGravatar(`https://www.gravatar.com/avatar/${hashedEmail}?s=200`);
  }, [email]);

  const handleNavigationClick = (name) => {
    setActiveNavItem(name);
    setSidebarOpen(false);
  };

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: "POST",
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = "/";
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };


  const navItemComponents = {
    Home: Deploy,
    Websites: Websites,
    Containers: Containers,
    CDN: CDN,
  };

  const ActiveComponent = navItemComponents[activeNavItem];

  return (
    <>
      <div>
        {/* Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          {/* Sidebar overlay */}
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            {/* Sidebar content */}
            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  {/* Close button */}
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar menu */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://cdn-icons-png.flaticon.com/128/15365/15365930.png"
                        alt="Webpagent"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() =>
                                    handleNavigationClick(item.name)
                                  }
                                  className={classNames(
                                    "w-full", // Full width
                                    item.current || activeNavItem === item.name
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold focus:outline-none"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://cdn-icons-png.flaticon.com/128/15365/15365930.png"
                alt="Webpagent"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => handleNavigationClick(item.name)}
                          className={classNames(
                            "w-full", // Full width
                            item.current || activeNavItem === item.name
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold focus:outline-none"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="flex gap-x-4">
              <a
                className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-white"
              >
                <img
                  className="h-8 w-8 rounded-full bg-gray-800 blur-none hover:blur-sm pointer-events-none"
                  src={gravatar}
                  alt=""
                />
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true" className="pointer-events-none">
                  {username}
                </span>
              </a>
              <button
                onClick={handleLogout}
                className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 ml-auto"
              >
                <ArrowLeftEndOnRectangleIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
                Logout
              </button>
            </div>
          </div>
        </div>

        {/* Mobile top navigation */}
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
                  className="h-8 w-8 rounded-full bg-gray-800 blur-none hover:blur-sm pointer-events-none"
                  src={gravatar}
                  alt=""
            />
          </a>
        </div>

        {/* Main content */}
        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            <ActiveComponent />
          </div>
        </main>
      </div>
    </>
  );
}
