import React, { useState, useEffect } from "react";
import Notification from "./notification";
import DeleteCDNObject from "./deleteCDNObject";

import {
  DocumentPlusIcon,
  DocumentCheckIcon,
  TrashIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/solid";

const fetchData = async (setCdnLinks, showNotification) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/api/cdn`;
    const options = {
      method: "GET",
      credentials: 'include',
    };
    const response = await fetch(endpoint, options);
    const data = await response.json();
    if (response.ok) {
      if (!data.webpagentSites) {
        setCdnLinks([]);
        return;
      }
      const links = data.webpagentSites.map((site) => ({
        name: site.fileName,
        objectId: site.webpagentCDNObjectId,
        link: site.url,
      }));
      setCdnLinks(links);
    } else {
      showNotification("Failed to fetch CDN data", "error");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    showNotification("Failed to fetch CDN data", "error");
  }
};

export default function CDN() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [cdnLinks, setCdnLinks] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [notification, setNotification] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleRemoveSelectedFiles = (e) => {
    e.stopPropagation();
    setSelectedFiles([]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const handleDeployCDN = async () => {
    try {
      document.getElementById("upload-button").disabled = true;
  
      if (selectedFiles.length === 0) {
        return;
      }
  
      setProcessing(true);
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
  
      const endpoint = `${process.env.REACT_APP_API_URL}/api/cdn`;
      const options = {
        method: "POST",
        headers: {
          credentials: 'include',
        },
        body: formData,
      };
  
      const response = await fetch(endpoint, options);
      if (response.ok) {
        fetchData(setCdnLinks, showNotification);
        showNotification("Deployment was successful. Please wait", "success");
        setSelectedFiles([]);
      } else {
        showNotification("Deployment was not successful", "error");
      }
    } catch (error) {
      showNotification("Deployment was not successful", error, "error");
    } finally {
      // Enable the upload button after the upload finishes
      document.getElementById("upload-button").disabled = false;
      setProcessing(false);
    }
  };
  

  const handleDeleteCDNObject = (objectId) => {
    setSelectedObjectId(objectId);
  };

  const handleDeleteSuccess = (deletedObjectId) => {
    setCdnLinks(cdnLinks.filter((link) => link.objectId !== deletedObjectId));
    showNotification("CDN Object deleted successfully", "success");
  };

  useEffect(() => {
    fetchData(setCdnLinks, showNotification);
  }, []);

  useEffect(() => {
    if (selectedFiles.length === 0) {
      setCdnLinks([]);
    }
  }, [selectedFiles]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div
        className="col-span-full relative"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Upload your website data
        </label>
        <label
          htmlFor="file-upload"
          className="mt-2 flex flex-wrap justify-center items-center rounded-lg border cursor-pointer border-dashed border-gray-900/25 px-6 py-10 relative"
        >
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="sr-only"
            onChange={handleFileSelect}
            multiple
            accept=".html, .css, .js, .png, .jpg, .jpeg, .gif, .svg, .mp4, .pdf"
          />
          {selectedFiles.length > 0 && (
            <button
              type="button"
              className="absolute top-0 right-0 p-1 m-2 bg-white rounded-full"
              onClick={handleRemoveSelectedFiles}
            >
              <TrashIcon className="h-5 w-5 text-red-500" />
            </button>
          )}
          {selectedFiles.length === 0 ? (
            <>
              <DocumentPlusIcon
                className="h-8 w-8 text-gray-300"
                aria-hidden="true"
              />
              <span className="mt-1 ml-1 text-xs text-gray-600">
                Drag and drop your files or click in this box to select files to
                upload (max. 50MB per Object)
              </span>
            </>
          ) : (
            selectedFiles.map((file) => (
              <div
                key={file.name}
                className="flex flex-col items-center mr-6 mb-4"
              >
                <DocumentCheckIcon
                  className="h-8 w-8 text-gray-300"
                  aria-hidden="true"
                />
                <p className="mt-1 text-xs text-gray-600">{file.name}</p>
              </div>
            ))
          )}
        </label>
      </div>
      <div className="mt-4">
        <button
          type="button"
          id="upload-button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleDeployCDN}
        >
          <CloudArrowUpIcon
            className="-ml-0.5 mr-1.5 h-5 w-5"
            aria-hidden="true"
          />
          Upload Files
        </button>
      </div>

      <div className="mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              CDN Links
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Here you can find the links to your cdn files
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Filename
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Object Id
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Link
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {cdnLinks.map((link) => (
                  <tr key={link.objectId}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 border-b border-gray-200">
                      {link.name}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 border-b border-gray-200">
                      {link.objectId}
                    </td>
                    <td className="whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell border-b border-gray-200">
                      <a
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        {link.link}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell border-b border-gray-200">
                      <button
                        type="button"
                        className="inline-flex items-center p-2 text-gray-400 hover:text-gray-600"
                        onClick={() => handleDeleteCDNObject(link.objectId)}
                      >
                        <TrashIcon className="h-5 w-5 text-red-500" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedObjectId && (
        <DeleteCDNObject
          objectId={selectedObjectId}
          onClose={() => setSelectedObjectId(null)}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </div>
  );
}
