import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/dashboard/dashboard';
import Landingpage from './components/landingpage';
import Login from './components/login';
import ErrorPage from './components/error';

async function verifyToken() {
  try {
    const endpoint = process.env.REACT_APP_API_URL + "/api/auth/check_token";
    const options = {
      method: 'GET',
      credentials: 'include',
    };

    const response = await fetch(endpoint, options);

    if (response.status !== 200) {
      return false;
    }
    
    return response.status === 200;
  } catch (error) {
    return false;
  }
}

function ProtectedRoute({ children }) {
  const [isVerified, setIsVerified] = React.useState(null);

  React.useEffect(() => {
    const verify = async () => {
      const result = await verifyToken();
      setIsVerified(result);
    };
    verify();
  }, []);

  if (isVerified === null) {
    return <div>Loading...</div>;
  }

  return isVerified ? children : window.location.href = process.env.REACT_APP_API_URL + '/api/auth';
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}
