import { useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import Deletewebsite from "./deletewebsite";
import Notification from "./notification";
import Home from "../home/deploy";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statuses = {
  deploying: "text-gray-600 bg-gray-50 ring-gray-500/10",
};

export default function Websites() {
  const [webpagentSites, setWebpagentSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [creatingWebsite, setCreatingWebsite] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const endpoint = `${process.env.REACT_APP_API_URL}/api/website`;
        const options = {
          method: "GET",
          credentials: 'include',
        };
        const response = await fetch(endpoint, options);
        const data = await response.json();
        if (data && data.webpagentSites) {
          setWebpagentSites(data.webpagentSites);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const handleCreateWebsiteClick = () => {
    setCreatingWebsite(true);
  };

  const handleBackToHome = () => {
    setCreatingWebsite(false);
  };

  const handleDeleteClick = (siteId) => {
    setSelectedSiteId(siteId);
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  if (isLoading) {
    return <div />;
  }

  if (creatingWebsite) {
    return <Home onBack={handleBackToHome} />;
  }

  if (webpagentSites.length === 0) {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            No Websites found in your account
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Get started by creating a new website. Once created, you can view
              and manage your websites here.
            </p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={handleCreateWebsiteClick}
            >
              Create a new website
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {/* Render Example component when selectedSiteId is not null */}
      {selectedSiteId && (
        <Deletewebsite
          webpagentSiteId={selectedSiteId}
          onClose={() => setSelectedSiteId(null)}
        />
      )}

      <ul
        role="list"
        className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
      >
        {webpagentSites.map((site) => (
          <li
            key={site.webpagentSiteId}
            className="overflow-hidden rounded-xl border border-gray-200"
          >
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
              <div className="text-sm font-medium leading-6 text-gray-900">
                {site.domain}
              </div>
              <div className="ml-auto">
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={"https://" + site.domain}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Open Website
                            <span className="sr-only">, {site.domain}</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() =>
                              handleDeleteClick(site.webpagentSiteId)
                            } // Call handleDeleteClick with siteId
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Delete
                            <span className="sr-only">, {site.domain}</span>
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Site ID</dt>
                <dd className="text-gray-700">{site.webpagentSiteId}</dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Status</dt>
                <dd className="flex items-start gap-x-2">
                  <div
                    className={classNames(
                      statuses[site.status],
                      "rounded-md py-1 px-2 text-xs font-medium ring-1 bg-green-500 ring-inset"
                    )}
                  >
                    {site.status}
                  </div>
                </dd>
              </div>
            </dl>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}
